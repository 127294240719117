.AppHeader {
  color: #EF476F;
  margin: 10px 10px 10px 20px;
}

header h1 {
  color: #06D6A0;
}

.AppFooter {
  color: #8DA7BE;
  margin: 10px 10px 10px 20px;
}
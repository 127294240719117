@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "JetBrains Mono", monospace;
  background-color: #282c34;
}

a:visited {
  color: #FB8B24;
}

.App-header {
  background-color: #282c34;
  color: #53917E;
}

.App-link {
  color: #8DA7BE;
}
.AppHeader {
  color: #EF476F;
  margin: 10px 10px 10px 20px;
}

header h1 {
  color: #06D6A0;
}

.AppFooter {
  color: #8DA7BE;
  margin: 10px 10px 10px 20px;
}
